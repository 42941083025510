import React from "react";
import { connect } from "react-redux";

import { setInfoSearch, clearInfoSearch } from "../../../state/app";

import SearchInput from "../../UI/Form/SearchInput";

const InfoNavigationSearch = ({ dispatch, infoSearch }) => {
  const handleInfoSearchUpdate = search => {
    dispatch(setInfoSearch(search))
  }
  const handleClearSearch = () => {
    dispatch(clearInfoSearch());
  }
  return (
    <SearchInput
      search={infoSearch}
      setSearch={handleInfoSearchUpdate}
      clearSearch={handleClearSearch}
    />
  )
}

export default connect(state => ({
  infoSearch: state.app.infoSearch,
}), null)(InfoNavigationSearch)
