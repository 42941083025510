import React from "react";

import { StyledNavigationContainer } from "./styles";
import PageNavigationPrevButton from "./PageNavigationPrevButton";
import PageNavigationNextButton from "./PageNavigationNextButton";
import PageNavigationCounter from "./PageNavigationCounter";

const PageNavigation = ({ setActivePage, activePage, lastPage, firstPage, totalPages }) => {
  const showNextPage = () => {
    setActivePage(activePage + 1);
  }
  const showPrevPage = () => {
    setActivePage(activePage - 1);
  }
  return (
    <StyledNavigationContainer>
      <PageNavigationPrevButton
        setActivePage={setActivePage}
        isShown={Boolean(!firstPage)}
        handleClick={showPrevPage}
      />
       <PageNavigationCounter
         activePage={activePage}
         totalPages={totalPages}
       />
      <PageNavigationNextButton
        setActivePage={setActivePage}
        isShown={Boolean(!lastPage)}
        handleClick={showNextPage}
      />
    </StyledNavigationContainer>
  )
}

export default PageNavigation