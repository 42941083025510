import React from "react";

import { StyledInputContainer, StyledSearch } from "./styles";
import IconSearch from "../../../assets/icons/search.svg";
import IconClose from "../../../assets/icons/close-button.svg";


const SearchInput = ({ search, setSearch, clearSearch }) => {
  const handleChange = event => {
    event.preventDefault();
    setSearch(event.target.value);
  }
  return (
    <StyledInputContainer className={ search.length && 'active' }>
      <IconSearch className="searchIcon" height="15" width="15" fill="currentColor" />
      <StyledSearch
        type="text"
        placeholder="Название"
        value={ search }
        onChange={ handleChange }
        />
      <div className="closeIcon">
        { Boolean(search.length) && (
          <IconClose
            height="15"
            width="15"
            fill="currentColor"
            onClick={() => clearSearch()}
          />
        )}
      </div>
    </StyledInputContainer>
  )
}

export default SearchInput