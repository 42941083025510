import React from "react";

import LeftArrowIcon from "../../../assets/icons/left-arrow.svg";
import { PageNavigationButton, PageNavigationButtonContainer } from "./styles";

const PageNavigationPrevButton = ({ handleClick, isShown }) => (
  <PageNavigationButtonContainer>
    {isShown ? (
      <PageNavigationButton onClick={handleClick}>
        <LeftArrowIcon
          width="24"
          height="24"
          style={{
            marginRight: "1rem",
          }}
        />
        <span>назад</span>
      </PageNavigationButton>
    ) : <div></div>}
  </PageNavigationButtonContainer>
)

export default PageNavigationPrevButton