import styled from "styled-components";

export const StyledNavigationContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const PageNavigationButton = styled.span`
  background-color: #f0f9ff;
  border: 2px solid #414c52;
  padding: .6rem 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 9rem;
`;

export const PageNavigationButtonContainer = styled.div`
  width: 12.5rem;
  height: 4.5rem;
`;

export const StyledPageNavigationCounter = styled.div`
  margin: 0 1rem;
`;