import React from "react";

import { StyledNavigationTop } from "../styles";
import PageNavigation from "../../UI/PageNavigation";
import InfoNavigationSearch from "./InfoNavigationSearch";

const InfoNavigationTop = ({ setActivePage, activePage, lastPage, firstPage, totalPages }) => {
  return (
    <StyledNavigationTop>
      <InfoNavigationSearch />
      <PageNavigation
        totalPages={totalPages}
        setActivePage={setActivePage}
        activePage={activePage}
        lastPage={lastPage}
        firstPage={firstPage}
      />
    </StyledNavigationTop>
  )
}

export default InfoNavigationTop