import React from "react";
import { Link } from "gatsby";

import { StyledInfoItemsList } from "./styles";

const InfoItemsList = ({ items }) => {
  return (
    <StyledInfoItemsList>
      { items.map(item => (
        <li key={item.slug}>
          <Link to={`/info/${item.slug}`}><span dangerouslySetInnerHTML={{__html: item.title}} /></Link>
        </li>
      ))}
    </StyledInfoItemsList>
  );
};

export default InfoItemsList;
  