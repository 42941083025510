import React from "react";

import LeftArrowIcon from "../../../assets/icons/left-arrow.svg";
import { PageNavigationButton, PageNavigationButtonContainer } from "./styles";

const PageNavigationNextButton = ({ handleClick, isShown }) => (
  <PageNavigationButtonContainer>
    {isShown && (
      <PageNavigationButton onClick={ handleClick }>
        <span>вперед</span>
        <LeftArrowIcon
          width="24"
          height="24"
          style={{
              transform: "rotate(180deg)",
              marginLeft: "1rem",
          }}
        />
      </PageNavigationButton>
    )}
  </PageNavigationButtonContainer>
)

export default PageNavigationNextButton