import InfoItem from "./InfoItemDTO";

export default class InfoItemsFactory {
  static createInfoItem(infoItemObject) {
    const infoItemDTO = new InfoItem(
      infoItemObject.title,
      infoItemObject.slug
    );
    return infoItemDTO;
  }

  static fromArray(infoApiArray) {
    return infoApiArray.map(({frontmatter}) => InfoItemsFactory.createInfoItem(frontmatter));
  }
}
