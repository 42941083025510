import styled from "styled-components";

export const StyledInputContainer = styled.div`
  border: 2px solid #414c52;
  padding: 0 1rem;
  color: #63717f;

  display: flex;
  align-items: center;

  &.active {
    outline: 2px solid #C2E9FF;
  }

  .searchIcon {
    margin-right: .8rem;
  }
  .closeIcon {
    width: 1.5rem;
    svg {
      cursor: pointer;
    }
  }
`;

export const StyledSearch = styled.input`
  border: none;
  padding: .5rem 1rem;
`
