import styled from 'styled-components';
import { Container } from "../styles/Container";

export const InfoItemsContainer = styled(Container)`
  padding: 1rem 0;
`;

export const StyledNavigationTop = styled.div`
  display: grid;
  grid-template-columns: repeat(2, auto);
  justify-content: space-between;
  padding: 1.5rem 0;
  border-bottom: 1px solid #eee;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-row-gap: 2rem;
    grid-auto-rows: 1fr;
    justify-items: start;
  }
`;

export const StyledNavigationBottom = styled.div``;

export const StyledInfoItemsList = styled.ul`
  margin: 3rem 0;
  li {
    padding: .4rem 0;
    :hover {
      background-color: #ebf2fd;
    }
  }
`;