import React from "react";
import { graphql } from "gatsby";
import { connect } from "react-redux";

import Layout from "../components/layout";
import SEO from "../components/seo";

import { InfoItemsContainer } from "../components/Info/styles";
import InfoNavigation from "../components/Info/InfoNavigation";
import InfoItemsList from "../components/Info/InfoItemsList";

import InfoItemsFactory from "../features/Info/InfoItemsFactory";
import Pagination from "../features/Common/Pagination";

import BreadcrumbsFactory from "../features/Layout/Breadcrumbs/BreadcrumbsFactory";
const breadcrumbsFactory = new BreadcrumbsFactory();

const Info = ({ data, infoSearch }) => {
  // Активная страница
  const [activePage, setActivePage] = React.useState(0);
  const [pageCount, setPageCount] = React.useState(0);
  const [pageItems, setPageItems] = React.useState([]);
  const [displayedItems, setDisplayedItems] = React.useState([]);
  // Получаем все записи по типу
  const infoItems = InfoItemsFactory.fromArray(data.items.nodes);

  React.useEffect(() => {
    // 1. Фильтруем по названию статьи
    const filteredItems = infoItems.filter(item => item.title.toLowerCase().includes(infoSearch.toLowerCase()));
    // 2. Разбиваем на страницы
    const paginationItems = Pagination.formatItems(filteredItems);
    // 3. Записываем общее количество страниц
    setPageCount(paginationItems.length);
    // 4. Записываем массив страниц
    setPageItems(paginationItems);
    // 5. Ставим активной первую страницу
    setActivePage(1);
    const displayedItems = paginationItems[0].items || [];
    setDisplayedItems(displayedItems)
  },[infoSearch]);

  React.useEffect(() => {
    if (activePage) {
      const displayedItems = pageItems[activePage - 1].items || [];
      setDisplayedItems(displayedItems)
    }
  }, [activePage]);

  const crumbs = breadcrumbsFactory.fromArray([
    {
      path: 'info',
      title: 'Статьи',
    },
  ]);
  return (
    <Layout crumbs={crumbs}>
      <SEO title="Все о Land Cruiser" />
      <InfoItemsContainer>
        <InfoNavigation
          setActivePage={setActivePage}
          activePage={activePage}
          totalPages={pageCount}
        >
          <InfoItemsList
            items={ displayedItems }
          />
        </InfoNavigation>
      </InfoItemsContainer>
    </Layout>
  );
};

export const query = graphql`
  query ALL_ARTICLES {
    items:allMarkdownRemark(
      sort: {order: ASC, fields: [frontmatter___sort]},
      filter: {fileAbsolutePath: {regex: "/articles/"}}
      ) {
      nodes {
        frontmatter {
          title
          slug
          sort
        }
      }
    }
  }
`;

export default connect(state => ({
  infoSearch: state.app.infoSearch,
}))(Info);