import React from "react";

import InfoNavigationTop from "./InfoNavigationTop";
// import InfoNavigationBottom from "./InfoNavigationBottom";

const InfoNavigation = ({
  children,
  setActivePage,
  activePage,
  totalPages,
  }) => {
  const isLastPage = (page, total) => page === total
  const isFirstPage = page => page === 1;
  return (
    <>
      <InfoNavigationTop
        totalPages={totalPages}
        setActivePage={setActivePage}
        activePage={activePage}
        lastPage={isLastPage(activePage, totalPages)}
        firstPage={isFirstPage(activePage)}
      />
      {children}
      {/* <InfoNavigationBottom /> */}
    </>
  )
}

export default InfoNavigation